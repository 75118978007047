import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "description"]

  initialize() {
    this.update()
  }

  update() {
    for (const target of this.descriptionTargets) {
      target.hidden = target.dataset.plan != this.inputTarget.value
    }
  }
}
