import { Controller } from "stimulus"
import { createElement, nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["entry", "header"]

  connect() {
    this.scheduleUpdate()
    this.recordUpdate()
  }

  disconnect() {
    this.cancelUpdate()
  }

  // Private

  scheduleUpdate() {
    this.updateTimeout = setTimeout(() => this.update(), this.updateInterval)
  }

  cancelUpdate() {
    clearTimeout(this.updateTimeout)
  }

  recordUpdate() {
    this.lastUpdateAt = new Date()
  }

  async update() {
    const updates = await this.fetchUpdates()
    if (!updates) return this.cancelUpdate()
    if (this.hasTargets(updates)) this.recordUpdate()
    if (this.pageIsVisible) await nextFrame()
    this.element.prepend(updates)
    this.removeDuplicateHeaders()
    this.scheduleUpdate()
  }

  async fetchUpdates() {
    const url = new URL(location)
    url.searchParams.set("after", this.targetId(this.entryTarget))
    url.searchParams.set("since", this.lastUpdateAt.toISOString())
    const response = await fetch(url, { headers: { accept: "text/html; fragment" } })
    const template = createElement("template", { innerHTML: await response.text() })
    if (response.ok) return template.content
  }

  removeDuplicateHeaders() {
    const { headerTargets } = this
    while (headerTargets.length) {
      const headerId = this.targetId(headerTargets.shift())
      for (const header of headerTargets) {
        if (this.targetId(header) == headerId) header.remove()
      }
    }
  }

  hasTargets = (root) => !!root.querySelector(`[data-${this.identifier}-target]`)
  targetId = (target) => target.getAttribute(`data-${this.identifier}-id`)

  get updateInterval() {
    return this.pageIsVisible ? 10000 : 30000
  }

  get pageIsVisible() {
    return document.visibilityState == "visible"
  }
}
